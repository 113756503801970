*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration-line: none;
 
}

body,html{
  height: 100%;
  overflow: hidden;
}
@font-face {
  font-family: 'fontspring-extralight';

src: local('MyFont'), url(./assets/Fontspring-DEMO-altivo-extralight.otf) format('truetype');
}
@font-face {
  font-family: 'fontspring-medium';

  src: local('MyFont'), url(./assets/Fontspring-DEMO-altivo-medium.otf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('MyFont'), url(./assets/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Thin';
  src: local('MyFont'), url(./assets/Lato-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'fontspring-regular';
  src: local('MyFont'), url(./assets/altivo-regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato-Hairline';
  src: local('MyFont'), url(./assets/Lato-Hairline-6.ttf) format('truetype');
}
#root{
  height: 100%;
}
.App{
height: 100%;
}